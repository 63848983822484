/* Sidebar Base Styling */
#user-sidebar {
    width: 260px;
    background-color: #fff;
    transition: all 0.3s;
    position: fixed;
    left: 0;
    min-height: 100vh;
    z-index: 10;
    top: 0;
}

#user-sidebar.user-active {
    margin-left: 0;
    /* When the sidebar is active, it should be fully visible */
}

.user-sidebar-logo {
    width: 120px;
    margin-left: 50px;

}

.user-components {
    padding: 0;
}

.user-components li {
    font-size: 20px;
    transition: color 0.3s ease;
}

.user-components li a {
    padding: 10px 0;
    margin: 10px 30px;
    display: block;
    color: #00349A;
}

.user-active-sidebar {
    border-left: 4px solid rgba(0, 52, 154, 0.9);
    background-color: #dddddd54;
}

#user-sidebar ul li:hover {
    background-color: #dddddd54;
}

/* Menu Bars Icon Styling */
.user-menu-bars {
    display: none;
    padding: 10px 0px 0px 10px;
    background: none;
    border: none;
    font-size: 30px;
    z-index: 5;
    color: #002d85;
    background-color: #f7f9fc;
    width: 100%;
}

/* Close button styling */
.user-custom-menu {
    position: absolute;
    top: -1px;
    right: 12px;
    z-index: 200;
}

.user-custom-menu button {
    border: none;
    color: #002d85;
    background: none;
    font-size: 31px;
    display: none;
    /* Initially hidden */
}

/* Dashboard Container */
.dashboard-container {
    display: flex;
}

/* Sidebar and Main Content */
.dashboard-main {
    width: 100%;
    min-height: 100vh;
    padding-left: 260px;
    /* Default padding when the sidebar is visible */
    transition: padding-left 0.3s ease;
    background-color: #f7f9fc;
}

/* When the sidebar is toggled off on small screens */
.dashboard-main.shifted {
    padding-left: 0;
    /* Shift content when sidebar is hidden */
}

/* Main content styling */
.dashboard-content {
    padding: 1px 20px;
    background-color: #f7f9fc;
    min-height: 100vh;
}

/* Dashboard Header */
.dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    color: #00349A;
    background-color: #f7f9fc;
    margin-bottom: 50px;
}

/* Toggle Button for Bars */
.toggle-button {
    display: none;
    /* Hidden by default */
    background: none;
    border: none;
    font-size: 28px;
}

/* Add a line between steps */
.ant-steps-item {
    position: relative;
}

.ant-steps-item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    width: 40px;
    /* Adjust this value based on the spacing between steps */
    height: 2px;
    /* Thickness of the line */
    background-color: #d9d9d9;
    /* Default line color */
    transform: translateY(-50%);
}

/* Highlight the line for active and completed steps */
.ant-steps-item.ant-steps-item-finish~.ant-steps-item::after,
.ant-steps-item.ant-steps-item-active~.ant-steps-item::after {
    background-color: #1677ff;
    /* Active/Completed line color */
}

/* Media Query for screen sizes below 1000px */
@media (max-width: 1000px) {
    #user-sidebar {
        margin-left: -345px;
        /* Sidebar is hidden by default */
    }

    #user-sidebar.user-active {
        margin-left: 0;
        /* When sidebar is active, slide in */
    }

    .inc-dec-btn {
        display: none !important;
    }

    .user-custom-menu button {
        display: block !important;
        /* Show the close button */
    }

    .user-menu-bars {
        display: flex;

    }

    /* Hide sidebar when screen width is below 1000px */
    .dashboard-main {
        padding-left: 0;
    }

    .dashboard-main.shifted {
        padding-left: 0;
        /* No extra space when sidebar is hidden */
    }

    /* Show toggle button (bars icon) */
    .toggle-button {
        display: block;
    }
}