@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  /* font-family: "Roboto", serif; */
  scroll-behavior: smooth;
  /* font-family: "Open Sans", serif; */
}

html {
  scroll-behavior: smooth;
}

.nav-title {
  font-family: "Roboto", serif;
  font-weight: 700;
  font-size: 32px;
}

.roboto {
  font-family: "Roboto", serif;
}

a {
  text-decoration: none !important;
}

.mt-top {
  margin-top: 95px;
}

.m-h-100 {
  min-height: 100vh;
}

.text-coomon-color {
  color: #0C253B !important;
}

.m-h-75 {
  min-height: 75vh;
}

.animate {
  display: inline-block;
  /* Ensure the element can be transformed */
  animation: scaleUpDown 2s infinite, moveUpDown 2s infinite;
}

.b-clr {
  color: #3445b4;
}

/* Keyframe for scaling up and down */
@keyframes scaleUpDown {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
    /* Adjust the scale factor as needed */
  }
}

/* Keyframe for moving up and down */
@keyframes moveUpDown {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
    /* Adjust the movement distance as needed */
  }
}


.m-h-50 {
  min-height: 50vh;
}

.banner-space {
  padding: 150px 0px;
}

.common-space {
  padding: 80px;
}

.bg-little-dark {
  background-color: #f8f7f6;
}

.accept-btn-c {
  padding: 10px 30px;
  border: 2px solid #091629;
  transition: 0.5s ease;
}

.accept-btn {
  background-color: #091629;
  color: #fff;
}

.accept-btn:hover {
  color: #091629;
  background-color: #fff;
}

.decline-btn {
  color: #091629;
  background-color: #fff;
}

.decline-btn:hover {
  background-color: #091629;
  color: #fff;
}

.bg-orng {
  background-color: #F2714D;
}

.d-clr {
  color: #666666 !important;
}

.c-clr {
  color: #BDA368 !important;
}

.b-clr {
  color: #3445b4;
}

.bg-clr {
  background-color: #BDA368;
}

.bg-clr-g {
  background-color: #0D233A;
}

.common-title {
  font-family: "Rubik", sans-serif;
  font-size: 50px;
  font-weight: 500;
  line-height: 60px;
  color: #fff;
}

.common-text-g {
  margin: 5px 0px;
  font-size: 18px;
  font-weight: 400;
  color: #1e3351;
}

.bc-clr {
  color: #1e3351;

}

.common-sm-title {
  font-weight: 700;
  font-size: 35px;
  line-height: 70px;
  color: #666;
}

.t-dark {
  color: #969696 !important;

}

.common-text {
  margin: 10px 0px;
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  color: #FFFFFF;
}

.border-btn {
  color: #BDA368;
  background-color: #fff;
  border: 2px solid #BDA368;
  border-radius: 20px;
  padding: 10px 25px;
  text-decoration: none;
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  transition: 0.5s ease;
}

.border-btn:hover {
  background-color: #BDA368;
  color: #fff;
}

.logo {
  width: 160px;
}

.open-nav {
  display: none;
}


.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  display: none;
  background-color: transparent;
  border: none;
}

.close-btn-deposit-sidebar {
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  background-color: transparent;
  border: none;
}


.login-btn {
  background-color: #BDA368;
  color: #FFFFFF;
  padding: 8px 20px;
  border-radius: 10px;
  margin-left: 40px;
  font-size: 22px;
  font-weight: 500;
}

.login-btn:hover {
  color: #FFFFFF;
}

.dropdown-content {
  display: none;
  padding: 0px 10px;
  position: absolute;

  top: 32px;
  left: 110px;
  z-index: 1;
}

.active-nav {
  color: #BDA368 !important;

}

.active-menu {
  right: 0px !important;
}

.active-deposit-menu {
  right: 0px !important;
}

.active-transaction-sidemenu {
  right: 0px !important;
}

.nav-link {
  text-decoration: none !important;
  position: relative;
  font-weight: 400 !important;
  font-size: 22px !important;
  margin: 0px 18px;
  color: #FFFFFF !important;
}

.nav-link-nhv {
  text-decoration: none !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  margin: 5px;
  color: #666 !important;
  transition: 0.5s;

}

.nav-link-nhv:hover {
  color: #BDA368 !important;
}

.nav-link::after {
  content: '';
  width: 0%;
  height: 3px;
  background: #BDA368;
  position: absolute;
  left: 0;
  bottom: -6px;
  transition: 0.5s;
}

.nav-link:hover {
  color: #BDA368 !important;
}

.nav-link:hover::after {
  width: 100%;

}

.custom-nav-item {
  position: relative;
}

.custom-nav-link {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.custom-nav-link:hover {
  color: #ffa500;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  padding: 10px;
  margin: 0;
  min-width: 150px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.custom-dropdown-item {
  padding: 10px;
  color: #333;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.custom-dropdown-item:hover {
  background-color: #f1f1f1;
}

.custom-login-btn {
  background-color: #ffa500;
  padding: 10px 20px;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-expired {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.custom-login-btn:hover {
  background-color: #e69500;
}

.navbarr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e3351;
  padding: 5px 90px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, .1) !important;
}

.inc-dec-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inc-dec-btn {

  border: none;
  background: transparent;
  color: #00349A;
  border: 2px solid #00349A;
  padding: 0px 3px;
  border-radius: 100%;
  cursor: pointer;
  margin-right: 10px;
}

.set-inc-dec-btn {
  margin: 15px 26px 0px;
}

.manage-side {
  margin-top: 33px;
}

.navbar-left {
  display: flex;
  align-items: center;
  position: relative;
}

.freeSpace {
  width: 80px !important;
}

.freeSpaceDash {
  padding-left: 80px;
}

.rotateIcon {
  transform: rotate(180deg);
}

.open-nav {
  display: none;
}

.dropdown-menu-c {

  border-top: 3px solid #BDA368;
  position: absolute;
  display: none;
  list-style: none;
  box-shadow: 0 0 7px rgba(0, 0, 0, .1) !important;
  background-color: #fff;
  z-index: 20;
  width: 222px;
  padding-left: 0px !important;
}

.dropdown-menu-c li {
  padding: 5px 20px;
}

.dropdown:hover .dropdown-menu-c {
  display: block;
}



.common-nav-right {
  text-decoration: none;
  padding: 11px 25px;
  font-size: 22px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: center;
  border-radius: 30px;
  color: #fff;
  border: 2px solid #BDA368;
  background-color: #BDA368;
  transition: 0.5s ease;
}

/* navbar  */

/* banner  */

.banner-bg-color {
  background: linear-gradient(270deg, rgba(7, 18, 32, 0.33) 0%, rgba(9, 22, 40, 0.8) 33%, rgba(9, 22, 40, 0.87234) 50%, #091629 80%), url(assets/images/banner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.contact-bg-color {
  background: linear-gradient(270deg, rgba(7, 18, 32, 0.502881) 4.25%, rgba(11, 26, 48, 0.754322) 35.62%, #1e3351 79.86%), url(assets/images/contact-banner.png);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.career-bg-color {
  background: linear-gradient(270deg, rgba(7, 18, 32, 0.502881) 4.25%, rgba(11, 26, 48, 0.754322) 35.62%, #1e3351 79.86%), url(assets/images/career-banner.png);

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* auth  */
.reg-main {
  background: #FFFFFF;
  padding: 30px;
  min-height: 70vh;
}

.complaint-main {
  background: #FFFFFF;
  padding: 30px 0px;
  min-height: 70vh;
}

.reg-sub {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.reg-left {
  width: 50%;
  background: linear-gradient(rgba(14, 35, 64, 0.85), rgba(14, 35, 64, 0.85)), url(assets/images/signup-image.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 40px;
}

.login-left {
  width: 50%;
  background: linear-gradient(rgba(14, 35, 64, 0.85), rgba(14, 35, 64, 0.85)), url(assets/images/login-logo.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.login-inp {
  border: none;
  border-bottom: 1px solid #E6E6E6;
  padding: 10px;
  outline-color: #BDA368;
}

.login-wel {
  font-size: 17px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.19em;
  text-align: left;

}

.password-field {
  position: relative;
}

.password-field input {
  width: 100%;
  padding-right: 40px;
  /* Space for the eye icon */
}

.password-field .toggle-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #1e3351;
  /* Match the color of your button */
}

#togglePassword {
  position: absolute;
  top: 75%;
  right: 40px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 22px;
  color: #1e3351;
}

.login-sub {
  border: 3px solid #1e3351;
  border-radius: 27px;
  padding: 10px 0px;
  width: 47%;
  background-color: #fff;
  font-weight: 500;
  transition: 0.5s ease;
}

.login-sub-c {
  color: #fff;
  background: #1e3351;
}

.login-sub-c:hover {
  color: #1e3351;
  background-color: #fff;
}

.login-sub-r {
  color: #1e3351;
}

.login-sub-r:hover {
  color: #fff;
  background: #1e3351;
}

.login-t {
  font-family: "Lato", sans-serif;
  font-size: 32px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 3px solid #fff;
  margin-bottom: 20px;
}

.price-title {
  font-family: "Poppins";
  color: #00349A;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 15px;

}

.price-item {
  font-size: 24px;
  font-family: "sans-serif";
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: gray;
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  padding: 20px;
}

.fiat-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.crypto-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.reg-right {
  width: 50%;
  padding: 10px 50px;
}

.reg-right img {
  width: 140px;
  padding: 20px 0px;
}

.verify-card {
  box-shadow: 0px 4px 67px 0px #00000029;
  padding: 20px 40px;
  border-radius: 10px;
}

.pinCode-inp {
  width: 15%;
  height: 4rem;
  font-size: 1.5rem;
  text-align: center;
  padding: 10px;
  border: 2px solid #0C253B;
  border-radius: 0.5rem;

}

.login-btns-sub {
  display: flex;
  justify-content: space-between;
}

/* auth  */

/* contact  */
.inp-card {
  padding-top: 50x;
  display: flex;
  justify-content: center;
}

.inp-card-sub {
  width: 70%;
  background: #DEE3DC;
  border-radius: 16px;
  padding: 40px;
}

.get-t {
  color: #BDA368;
  font-size: 23px;
  font-weight: 500;
  line-height: 27.6px;
  text-align: left;

}

.c-form {
  color: #1e3351;
  font-size: 35px;
  font-weight: 500;
  line-height: 50.4px;
  text-align: left;


}

.auth-inp-main {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
}

.glad-h {
  font-family: "Lato", sans-serif;
  font-size: 35px;
  font-weight: 800;
  line-height: 43.75px;

}

.glad-t {

  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;

}

.form-inp-main {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
}

.contact-card-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label {
  color: #949494;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  text-align: left;
  margin: 10px 0px;
}

.form-inp {
  background: #FCFCFC;
  border: none;
  padding: 10px;
  border-radius: 6px;
  outline-color: #BDA368;
}

.auth-inp {
  border: 1px solid #D9D9D9;
  padding: 10px;
  border-radius: 6px;
  outline-color: #BDA368;
}

.sub-btn {
  color: #FCFCFC;
  background-color: #BDA368;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  font-size: 22px;
}

.sub-btn-b {
  color: #FCFCFC;
  background-color: #1e3351;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
}

.driven-title {
  color: #BDA368;
  margin: 10px 0px;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;

}

.career-border {
  border-left: 1px solid #E9E9E9;
  padding: 5px 20px;
  margin: 40px 0px;
}

/* idx  */
/* disclaimer  */
.disclaimer-m {
  display: flex;
  justify-content: center;
}

.disclaimer-sub {
  width: 50%;
}

/* card  */

.home-card-main {
  display: flex;
  justify-content: center;
}

.home-card {
  width: 90%;
  background-color: #0D233A;
  border-radius: 20px;
  margin: 40px 0px;
}

.home-card-sub {
  padding: 50px;
  display: flex;
}

.home-card-left {
  border-right: 1px solid #FFFFFF;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-card-right {

  width: 70%;
  padding-left: 30px;
}

.home-card-text {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #FFFFFF;
}

.card-text {
  margin-top: 15px;
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  color: #FFFFFF;

}

.btns-main {
  background: #DEE3DC;
  padding: 70px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns-sub {
  text-transform: uppercase;
  border: 1px solid #0C253B;
  padding: 12px 70px;
  margin: 0px 20px;
  border-radius: 8px;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
  transition: 0.5s ease;
}

.bg-c-w {
  background-color: #0C253B;
  color: #fff;
}

.f-btn {
  color: #0C253B;
}

.f-btn:hover {
  background-color: #0C253B;
  color: #fff;
}

.s-btn {
  background-color: #0C253B;
  color: #fff;
}

.s-btn:hover {
  color: #0C253B;
  background-color: #DEE3DC;
}

.career-img {
  width: 100%;
  height: 100%;

}

/* footer  */
.ftr {
  background: #0C253B;

}

.ftr-logo {
  width: 200px;
  margin-bottom: 40px;
}

.ftr-small-title {

  font-size: 23px;
  font-weight: 500;
  line-height: 49.45px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 21px !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  /* Light background to cover content */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  /* Ensure it appears above other content */
}

.sub-btn:disabled {
  cursor: not-allowed;
  /* Indicate disabled state */
}

.page-not {
  background-color: #091629;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.page-not-found-logo {
  font-size: 170px;
  margin-bottom: 40px;
}

.ftr-link a {
  position: relative;
  font-size: 20px;
  font-weight: 400;
  line-height: 32.25px;
  text-align: left;
  color: #FFFFFF;
  transition: color 0.35s ease-out;
  padding: 5px 0px;
}

.ftr-link a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.7px;
  bottom: 1px;
  left: 0;
  background-color: #BDA368;
  transform-origin: bottom right;
  transition: transform 0.35s ease-out;
}

.ftr-link a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.ftr-link a:hover {
  color: #BDA368;
}

.ftr p {
  font-size: 18px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: #AAAAAA;

}

/* modal section  */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 74%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.acc-circle {
  background-color: #BDA368;
  padding: 10px 17px;
  color: #fff;
  border-radius: 100px;
  margin-left: 40px;
  font-size: 20px;
  line-height: 30px;
}

.modal-content {
  width: 520px !important;
  background: white !important;
  padding: 20px 30px 40px 30px;
  border-radius: 8px !important;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-change {
  background-color: #0C253B;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-close {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

/* modal section  */
/* deposit card  */
.deposit-card {
  width: 70%;
  margin: 0px auto;
  background: #FFF;
}

/* user  */
.user-cards-main {
  display: flex;
  justify-content: space-between;
}

.dashboard-user-card {
  width: 19%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.redemption-title {
  font-size: 45px;
  font-family: "Inter";
  padding: 14px 0px 30px 0px;
  font-weight: 700;
  color: #1e3351;
}

.redemption-icon {
  text-align: center;
  font-size: 45px;
  color: #1e3351;
}

.withdraw-lable {
  font-family: "Inter";
  font-size: 22px;
}

.redemption-form .form-control {
  padding: 10px 15px !important;
  height: 50px !important;
  font-size: 21px !important;
}

.redemption-form .css-1dimb5e-singleValue {
  font-size: 21px;
}

.redemption-form .css-13cymwt-control {
  min-height: 51px;
}

.redemption-form .css-13cymwt-control span {
  font-size: 21px;
}

.deposit-address-sec .css-13cymwt-control {
  background-color: #0D233A;
}

.deposit-address-sec .css-1jqq78o-placeholder {
  color: #fff;
}

.deposit-address-sec label {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.qr-code {
  margin: 10px;
  background-color: #0D233A;
  text-align: center;
  border-radius: 5px;
}

.new-wallet-design {
  display: flex;
  align-items: center;
}

.wallet-transaction-sub {
  width: 100%;
}

.wallet-trans-sm-text {
  color: #1e3351;
  font-family: "Open Sans", serif;
}

.wallet-transaction-sub hr {
  margin: 0.2rem !important;
}

.deposit-wallet-label {
  padding: 0px 15px;
}

.wallet-deposit-sidebar {
  background-color: #1e3351;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -350px;
  height: 100%;
  width: 350px;
  padding: 5px 20px;
  transition: 0.3s;
  color: #fff;
}

.transaction-detail-sidebar {
  background-color: #1e3351;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -450px;
  height: 100%;
  width: 450px;
  padding: 5px 20px;
  transition: 0.3s;
  color: #fff;
}

.deposit-address-chara {

  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.deposit-address-copy-btn {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 25px;

}

.dashboard-user-card-icon-main {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard-user-card-icon {
  background-color: #f7f9fc;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #00349A;
  font-size: 25px;
}

.dashboard-user-card-text {
  color: gray;
  font-size: 18px;
}

.asset-balance-sec {
  background: #e0e8f5;
  padding: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wallet-value {
  font-size: 24px;
  color: #00349A;
  font-weight: 700;
  font-family: "Roboto", serif;
}

.asset-drop {
  background: none;
  font-size: 20px;
}

.wallet-value-sm {
  font-size: 18px;
  color: gray;
  font-family: "Open Sans", serif;
}

.wallet-amount {
  font-size: 40px;
  color: #1e3351;
  font-weight: 700;
  font-family: "Inter";
}

.currency-select {
  border-radius: 3px;
  border: none;
  padding: 2px;
  font-size: 12px;
  color: gray;
  outline-color: transparent;
}

.asset-select-wallet {
  border: none;
  font-size: 22px;
  color: #1e3351;
  background: transparent;
  padding: 4px;

}

.asset-select-wallet:hover {
  background: transparent !important;
}

.selected-asset-wallet {
  color: #1e3351;
  font-size: 22px;
  font-family: "Open Sans", serif;
  font-weight: 600;
}

.selected-network-wallet {
  color: gray;
  font-size: 18px;
  font-family: "Open Sans", serif;
  font-weight: 600;
}

.network-select-wallet:hover {
  background: transparent !important;
}

.network-select-wallet {
  border: none;
  font-size: 22px;
  color: gray;
  background: transparent;
  height: auto;
  padding: 4px 8px;
}

.asset-sec {
  padding: 15px 2px;
  display: flex;
  justify-content: space-between;
}

.wallet-transactions-sec {
  margin-top: 50px;
}

.transaction-sidebar-w-text {
  color: #fff;
  font-size: 16px;
  font-family: "Open Sans", serif;

}

.copy-transaction-btn {
  border: none;
  background: none;
  color: #fff;
}

.transaction-address-sidebar {
  color: #fff;
  font-size: 19px;
  font-family: "Open Sans", serif;
  font-weight: 500;
}

.wallet-transaction {
  display: flex;
  /* align-items: center; */
  gap: 40px;
  margin-bottom: 10px;
  transition: 0.4s ease;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.wallet-transaction:hover {
  background: #e0e8f5;
}

.wallet-transaction:hover .wallet-transaction-circle {
  background: #fff;
}


.wallet-transaction-circle {
  height: 50px;
  width: 55px;
  border-radius: 100px;
  background: #e0e8f5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.wallet-trans-arrow-debit {
  transform: rotate(40deg);
  color: #e72c2c;
  font-size: 21px;
}

.wallet-trans-arrow-credit {
  transform: rotate(140deg);
  color: #198754;
  font-size: 21px;
}

.wallet-trans-amount {
  color: #1e3351;
  font-size: 22px;
  font-family: "Open Sans", serif;
  font-weight: 500;
}

.recent-activity-main {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  transition: 0.5s ease;
}

.transaction-row {
  transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.line-chart canvas {
  width: 100%;
  height: 100%;
}

.bar-chart canvas {
  width: 100%;
  height: 100%;
}

.recent-title {
  color: #00349A;
  font-size: 25px;
  padding: 0px 15px;
}

.kyc-title {
  color: #333;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.kyc-text {
  color: #999;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.kyc-text span {
  color: #333;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.common-kyc-button {
  border-radius: 5px;
  background: #00349A;
  width: 135px;
  height: 48px;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin: 20px;
}

.signature-buttons button {
  border-radius: 5px;
  background: #00349A;
  padding: 7px 20px;
  color: #FFF;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  margin: 10px;
}

.kyc-screen {
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;

}


.balance-item {
  width: max-content;
  display: flex;
  font-size: 20px;
  border-bottom: 1px solid #0C253B;
  margin-bottom: 5px;
}

.wallet-sec {
  background-color: #fff;
  box-shadow: 0px 3px 7px 0px rgba(0, 52, 154, 0.75);
  padding: 15px;
  border-radius: 7px;
}

.wallet-title {
  font-family: "Roboto", serif;
  color: #00349A;
  font-size: 30px;
  font-weight: 800;
}

.account-value {
  margin-bottom: 30px;
}

.account-balance {
  font-family: "Roboto", serif;
  font-size: 35px;
  color: #FFF;
  font-weight: 700;
}

.account-top {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mid-circle-sec {
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  align-items: center;
}

.asset-balance-btns {
  display: flex;
  gap: 40px;
}

.mid-circle-text {
  color: #1e3351;
  font-family: "Roboto", serif;
  font-size: 18px;
}

.circle-icon {
  color: #1e3351;
  font-size: 25px;
}

.mid-circle {
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #1e3351;
  border-radius: 100px;
  border: none;
}

.eye-btn {
  border: none;
  background: none;
  color: #00349A;
  font-size: 30px;
}



.co-wallet-border {
  border: 1px solid #00349A;
  border-radius: 6px;
}

.wallet-co-title {
  text-align: center;
  color: #0f3467;
  background: #e0e8f5;
  font-size: 28px;
  margin-bottom: 0;
  padding: 12px 0px;
}

.common-prop {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
}

.span-text {
  color: #333;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.inp-card-radio {
  border-radius: 16px;
  border: 1px solid #EEE;
  display: flex;
  align-items: center;
  padding: 10px 30px;
  margin-bottom: 20px;
  width: 48%;
  justify-content: space-between;
  cursor: pointer;
}

.inp-card-radio input[type="radio"]:checked {
  background-color: #03349B;

}

.personal-info {
  padding: 30px;
}

.personal-info-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.per-info-card {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.per-info-card label {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.personal-inp {
  border-radius: 5px;
  border: 1px solid #EEE;
  padding: 10px 15px;
}

.personal-inp::placeholder {
  color: #999;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.error-text {
  margin-top: 3px;
  color: #dc3545;
}

.upload-image-main {
  padding: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #EEE;
  border-radius: 10px;
  width: 90%;
}

.summary-sub {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.select-main {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-images-main-left {
  width: 48%;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.upload-images-main-right {
  width: 48%;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
}

.upload-image-text {
  color: #00349A;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.upload-image-text-right {
  color: #333;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.documents-main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.document-left {
  width: 48%;
}

.document-right {
  width: 48%;
}

.upload-icon svg {
  color: gray;
  font-size: 30px;
  margin-bottom: 6px;
}

.signature-container {
  border: 2px solid #ccc;
  border-radius: 10px;
}

.sigCanvas {
  width: 100%;
  height: 200px;
}

.drag-area {
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
  color: #888;
  background-color: #fafafa;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
}

.drag-area:hover {
  border-color: #999;
  background-color: #f0f0f0;
}

.progress-bar-file {
  height: 5px;
  width: 100%;
  background-color: #e0e0e0;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;

}

.cancel-btn {
  background-color: transparent;
  color: #003399;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
}

.summary-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.summary-main label {
  font-size: 22px;
}

.summary-sub span {
  font-size: 22px;
  border: 1px solid #0916298f;
  padding: 5px 10px;
  border-radius: 5px;
}

.signature-img-container {
  position: relative;
  width: 300px;
  height: 130px;
  overflow: hidden;
}

.add-bank-sec {
  width: 100%;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px 0px;
  border-radius: 5px;
}

.account-boxes {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 7px;
}

.account-inp {
  border: 2px solid #80808082;
  width: 70px;
  height: 50px;
  font-size: 27px;
  outline-color: #80808082;
  border-radius: 5px;
  padding: 10px 21px;
}

.add-bank-card {
  margin-bottom: 30px;
}

.add-bank-input {
  border: 2px solid #80808082;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 22px;

}

.wallet-address-input {
  border: 1px solid rgba(128, 128, 128, 0.568);
  width: 100%;
  padding: 4px 10px;
  border-radius: 3px;
  font-size: 18px;
  outline-color: #3498db;
}

.user-details-card {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);

  padding: 30px;
  margin-bottom: 30px;
}

.common-summary {
  width: 100%;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px 0px;
  border-radius: 5px;
}

.summary-img-sub {
  width: 300px;
}

.view-pdf {
  color: #fff;
  background-color: #091629;
  padding: 10px 30px;
  border-radius: 5px;
}

.new-kyc-btn {
  border: none;
  background-color: #003399;
  padding: 10px 30px;
  border-radius: 5px;
  color: #fff;

}

.summary-img-sign {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #333;
}

.uploaded-doc {
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.uploaded-doc p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.uploaded-image {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-preview {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1rem;
}

.pdf-icon {
  font-size: 2rem;
}

.view-link {
  margin-left: auto;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

.view-link:hover {
  text-decoration: underline;
}

.tick-icon {
  font-size: 30px;
  color: #03349B;
}

.charts-main {
  display: flex;
  justify-content: space-between;
}

.charts-left {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  width: 59%;
}

.charts-right {
  background-color: #fff;
  padding: 15px 5px;
  border-radius: 10px;
  width: 40%;
}

.chart-nav {
  background: #ECF2FF;
  padding: 5px 10px;
  border-radius: 3px;
  display: inline-grid;
  width: auto;
}

.chart-overview {
  color: #1e3351;
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}

.trans-btn {
  border-radius: 18.5px;
  color: #fff;
  border: none;
  padding: 4px 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trans-pending {
  background: #F39222;
}

.trans-success {
  background: #36D065;

}

.trans-failed {
  background: #EF2326;

}

.user-profile-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.reports-main {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.user-profile-image {
  border: 1px solid #C2C2C2;
  border-radius: 21px;
  padding: 20px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.user-profile-image-img {
  width: 90px;
  height: 93px;
  border-radius: 100%;
  position: relative;
}

.verify-icon {
  position: absolute;
  right: 3px;
  bottom: 3px;
}

.verify-icon svg {
  color: #37D266 !important;
  font-size: 30px;
}

.user-profile-image-img img {
  width: 100%;
  height: 100%;
  padding: 10px;

}

.user-profil-text {
  font-family: Lato;
  font-size: 22px;
  font-weight: 400;
  color: #9C9C9C;

}

.user-profile-sm-text {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.verified-text {
  color: #37D266;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.02em;
  text-align: left;
  padding-left: 5px;
}

.user-profile-edit-btn {
  border: 1px solid #D9D9D9;
  width: 87px;
  height: 43px;
  border-radius: 21.5px;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #000000;
}

.user-profile-image-section {
  display: flex;
  align-items: center;
}

.user-profile-personal {
  border: 1px solid #C2C2C2;
  border-radius: 21px;
  padding: 20px;
  margin: 30px 0px;
}

.user-personal-main {
  display: flex;
  justify-content: space-between;
}

.user-personal-main-left {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-bottom: 30px;
}

.filter-inp {
  background: #ECF2FF;
  border-radius: 10px;
  padding: 10px;
  border: none;
  outline-color: transparent;
}

.other-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other-filter-sub {
  display: flex;
  flex-direction: column;
  width: 24%;
}

.download-pdf {
  background: radial-gradient(circle, rgba(0, 52, 154, 0.9) 10%, #00349A 30%, #002d85 100%);
  border-radius: 40px;
  padding: 10px 30px;
  border: none;
  color: #fff;
}

.download-dropdown {
  position: absolute;
  top: 110%;
  left: -10px;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border-radius: 10px;
  width: 163px;
  padding: 10px;
}

.download-dropdown div {
  font-size: 20px;
  padding: 3px;
  cursor: pointer;
}

.date-filter-main {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.date-filter {
  width: 50%;
}

.reports-table thead tr th {
  color: #9C9C9C;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
}

.reports-table th,
td {
  padding: 10px 20px;
}

.clear-btn {
  background: radial-gradient(circle, rgba(0, 52, 154, 0.9) 10%, #00349A 30%, #002d85 100%);

  border-radius: 40px;
  padding: 10px 25px;
  border: none;
  color: #fff;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  background: #ECF2FF;
  border-radius: 9px !important;
  padding: 7px !important;
  outline-color: transparent;
}

.reports-table-wrapper {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  /* Prevent vertical scrolling (optional) */
  width: 100%;
  /* Adjust to parent container's width */
}

.reports-table {
  width: 100%;
  /* Ensure the table fits the wrapper */
  border-collapse: collapse;
  /* Standardize table appearance */
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-avatar.ant-avatar-icon {
  font-size: 28px;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-avatar {
  height: 50px;
  width: 50px;
}

.profile-name {
  font-size: 28px;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {

  font-size: 21px;
}

:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon,
:where(.css-dev-only-do-not-override-1uq9j6g).ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-icon {

  margin-inline-end: 15px;
  font-size: 21px;
}

@media only screen and (max-width: 1280px) {

  .reports-table th,
  td {
    padding: 10px;
  }
}

@media only screen and (max-width: 992px) {

  .reports-table th,
  td {
    padding: 10px;
  }
}

@media only screen and (max-width: 962px) {

  .home-card {
    width: 90%;
  }

  .home-card-sub {
    padding: 40px;
  }

  .inp-card-sub {
    width: 90%;
  }

  .reg-right {
    padding: 10px 18px;
  }

  .user-cards-main {
    flex-wrap: wrap;
  }

  .dashboard-user-card {
    width: 24%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .charts-main {
    flex-direction: column;
  }

  .charts-left {
    width: 100%;
    margin: 20px 0px;
  }

  .charts-right {
    width: 100%;
  }
}

@media only screen and (max-width: 862px) {

  .navbarr {
    padding: 5px 55px;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-steps {
    flex-direction: column !important;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-inline-start: 0px !important;
    padding-bottom: 6px;

  }
}

@media only screen and (max-width: 762px) {
  .common-space {
    padding: 20px;
  }

  .career-img {
    height: auto;
    margin-bottom: 30px;
  }

  .c-form {
    line-height: 42px;
  }

  .disclaimer-sub {
    width: 100%;
  }

  .open-nav {
    display: block;
    font-size: 35px;
    color: #fff;
    background-color: transparent;
    border: none;
  }

  .close-btn {
    display: block;
  }

  #sidemenu {
    background-color: #1e3351;
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    position: fixed;
    top: 0;
    right: -250px;
    height: 100%;
    width: 250px;
    padding-top: 75px;
    transition: 0.3s;
    color: #BDA368;
  }

  .nav-link {
    margin: 8px 15px;
  }

  .navbarr {
    padding: 5px 40px;
  }

  .login-btn {
    margin: 13px;

  }

  .home-card-sub {
    flex-direction: column;
  }

  .home-card-left {
    width: 100%;
    border-right: none;
    margin-bottom: 30px;
  }

  .home-card-right {
    width: 100%;
    padding-left: 0px;
  }

  .reg-left {
    display: none;
  }

  .login-left {
    display: none;
  }

  .reg-right {
    width: 100%;
    box-shadow: 1px 2px 17px 1px rgba(14, 35, 64, 0.75);
    border-radius: 10px;
  }

  .verify-card {
    padding: 20px;
  }

  .kyc-screen .w-50 {
    width: 100% !important;
  }

  .select-main {
    flex-direction: column;
  }

  .inp-card-radio {
    width: 100%;
  }

  .common-prop {
    border-radius: 20px;
  }

  .personal-info {
    padding: 10px;
  }

  .personal-info-card {
    flex-direction: column;
  }

  .per-info-card {
    width: 100%;
  }

  .summary-sub {
    width: 100%;
  }

  .dashboard-user-card {
    width: 49%;
  }

  .tbl-main {
    overflow: scroll;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 672px) {
  .personal-info {
    width: 100%;
  }

  .date-filter-main {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }

  .date-filter {
    width: 100%;
  }

  .other-filter {
    flex-wrap: wrap;
    gap: 10px;
  }

  .other-filter-sub {
    width: 49%;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-avatar.ant-avatar-icon {
    font-size: 20px;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-avatar {
    height: 35px;
    width: 35px;
  }

  .document-left {
    width: 100%;
  }

  .document-right {
    width: 100%;
  }
}

@media only screen and (max-width: 562px) {
  .banner-space {
    padding: 100px 0px;
  }

  .common-title {
    font-size: 35px;
    line-height: 44px;
  }

  .navbarr {
    padding: 5px 10px;
  }

  .home-card {
    width: 95%;
    border-radius: 11px;
  }

  .home-card-sub {
    padding: 20px 10px;
  }

  .home-card-left {
    margin-bottom: 15px;
  }

  .home-card-left img {
    width: 100px;
  }

  .card-text {
    font-size: 31px;
    line-height: normal;

  }

  .btns-sub {

    padding: 9px 21px;
    margin: 0px 10px;

    font-size: 17px;
    line-height: 0px;

  }

  .ftr p {
    font-size: 17px;
  }

  .inp-card-sub {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
  }

  .c-form {
    font-size: 29px;
    line-height: 31px;
  }

  .form-label {
    font-size: 15px;
  }

  .form-inp {
    padding: 7px;
  }

  .form-inp-main {
    margin: 9px 0px;
  }

  .reg-main {
    padding: 30px 20px;
  }

  .reg-right {
    padding: 10px 14px;
  }

  .reg-right img {
    padding: 5px 0px;
  }

  .login-btns-sub {
    flex-direction: column;
  }

  .login-sub {
    width: 100%;
  }

  .login-sub-c {
    margin-bottom: 15px;
  }

  .pinCode-inp {
    height: 3rem;
  }

  .dashboard-content {
    padding: 10px;

  }

  .kyc-screen {
    width: 100%;
    min-height: 70vh;
  }

  .kyc-title {
    font-size: 30px;
  }



  .upload-images-main-right {
    width: 100%;
  }

  .kyc-screen {
    padding: 0px 10px;
  }

  .kyc-text {

    font-size: 22px;
    font-weight: 400;

  }

  .other-filter-sub {
    width: 100%;
  }
}

@media only screen and (max-width: 473px) {
  .date-filter div {
    flex-direction: column !important;
    align-items: normal !important;
  }

  .date-filter button {
    width: fit-content !important;
  }

  .date-filter .w-50 {
    width: 100% !important;
  }
}