@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

a {
    text-decoration: none !important;
}

#sidebar {

    min-width: 270px;
    max-width: 345px;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: fixed;
    left: 0;
    min-height: 100vh;
    z-index: 10;
    border-right: 0.5px solid gray;
}

#sidebar .h6 {
    color: #fff;
}

#sidebar.active {
    margin-left: -270px;
}

#sidebar h1 {
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 30px;
}

#sidebar h1 .logo {
    color: #fff;
}

#sidebar h1 .logo span {
    font-size: 14px;
    color: #44bef1;
    display: block;
}

#sidebar ul.components {
    padding: 0;
}

#sidebar ul li {
    font-size: 20px;
    margin: 10px;
    transition: color 0.3s ease;
}

#sidebar ul li>ul {
    margin-left: 10px;
}

#sidebar ul li>ul li {
    font-size: 14px;
}

#sidebar ul li a {
    padding: 10px 0px;
    margin: 10px 30px 10px;
    display: block;
    color: gray;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}





@media (max-width: 991.98px) {
    #sidebar {
        margin-left: -345px;
    }

    #sidebar.active {
        margin-left: 0;
    }
}

#sidebar .custom-menu {
    display: inline-block;
    position: absolute;
    top: -1px;
    right: 12px;
    margin-right: 0px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 0;
}

.custom-menu button {
    border: none;
    color: #002d85;
    background: none;
    font-size: 31px;
    display: none;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar .custom-menu {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}



#sidebar .custom-menu .btn i {
    margin-right: -10px;
    font-size: 17px;
    color: #fff;

}



#sidebar .custom-menu .btn.btn-primary:after {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #3445b4;
    border-radius: 10px;
}



a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991.98px) {
    #sidebarCollapse span {
        display: none;
    }
}

#content {
    width: 100%;
    padding: 0;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    margin-left: 345px;

}

.btn.btn-primary {
    background: #3445b4;
    border-color: #3445b4;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
    background: #3445b4 !important;
    border-color: #3445b4 !important;
}

.footer p {
    color: rgba(255, 255, 255, 0.5);
}

.form-control {
    height: 40px !important;
    background: #fff;
    color: #000;
    font-size: 13px;
    border-radius: 4px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: transparent;
}

.form-control:focus,
.form-control:active {
    border-color: #000;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
}

.subscribe-form .form-control {
    background: #3b4ec6;
}


@media (max-width: 992px) {
    .custom-menu button {
        display: block !important;
    }

    #content {
        margin-left: 0px !important;
    }

    .bars-btn {
        display: block !important;
    }

    #content {
        padding-top: 0px !important;
    }
}

.bars-btn {
    border: none;
    color: #002d85;
    background: none;
    font-size: 31px;
    display: none;
}

.bars-btn i {
    color: #fff;
    padding: 5px;
    font-size: 32px;

}

.margin-left-open {
    margin-left: 0px !important;
}

.margin-left-closed {
    margin-left: -270px !important;
}

.active-sidebar {
    background: radial-gradient(circle,
            rgba(0, 52, 154, 0.9) 10%,
            #00349A 30%,
            #002d85 100%);
    border-radius: 30px;
}

.active-sidebar a {
    color: #fff !important;
}

#sidebar ul li:hover {
    background: radial-gradient(circle,
            rgba(0, 52, 154, 0.9) 10%,
            #00349A 30%,
            #002d85 100%);
    border-radius: 30px;
    color: #fff !important;
}

#sidebar ul li:hover a {
    color: #fff !important;
    /* Change text color to white on hover */
}

.sidebar-logo {
    width: 120px;
    margin-left: 50px;
    margin-bottom: 20px;
}

/* font-family: "Inter", sans-serif; */


.boxes-main {
    display: flex;
    flex-wrap: wrap;

}

.box-btn {
    box-shadow: 0px 4px 25px 0px #00000026;
    background: #FFFFFF;
    color: #00349A;
    border-radius: 15px;
    padding: 10px 20px 5px 20px;
    margin: 10px;
    width: 156px;
    height: 127px;
    transition: 0.4s ease;
}

.box-title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;

}

.box-text {
    font-family: Inter;
    font-size: 11px;
    font-weight: 500;
    line-height: 13.31px;
    color: #878787;
    margin-bottom: 0px !important;
}

.box-btn-icon {
    font-size: 30px;
    margin-bottom: 15px;
}

.box-btn:hover {
    background: #00349A;
    color: #FFFFFF;
}

.box-btn:hover .box-text {
    color: #FFFFFF;
}

.search-inp {
    border: 2px solid #00349A;
    border-radius: 7px;
    outline-color: #00349A;
    width: 100%;
    padding: 10px 10px 10px 55px;

}

.search-inp::placeholder {
    color: #00349A;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
}

.search-main {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin-top: 30px;
}

.search-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: #00349A;
    font-size: 24px;
}

.add-user-btn {
    background: #00349A;
    border-radius: 24px;
    padding: 10px 30px;
    color: #FFFFFF;
    border: none;
    margin-right: 30px;
}

.search-add-main {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tbl-main {
    margin-top: 40px;
}

.simple-table th {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #000000;
    padding-bottom: 20px;
}

.username {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    color: #000000;

}

.simple-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.simple-table th,
.simple-table td {
    padding: 10px;
    text-align: left;
}

.simple-table th {
    background-color: #f2f2f2;
}

.simple-table td a {
    text-decoration: none;

}

.simple-table td a:hover {
    text-decoration: underline;
}

.kyc-status {
    padding: 8px 18px;
    border-radius: 5px;
    font-weight: bold;
}

.kyc-not-submitted {
    background-color: #f8d7da;
    color: #721c24;
}

.kyc-pending {
    background-color: #fff3cd;
    color: #856404;
}

.kyc-verified {
    background-color: #d4edda;
    color: #155724;
}

.kyc-rejected {
    background-color: #f8d7da;
    color: #721c24;

}

.user-status {
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
}

.status-active {
    background-color: #d4edda;
    color: #155724;
}

.status-locked {
    background-color: #f8d7da;
    color: #721c24;
}

.status-temp-locked {
    background-color: #ffeeba;
    color: #856404;
}


/* Chevron icon styling */
.chevron-cell {
    cursor: pointer;
    text-align: center;
}

.chevron-icon {
    transition: transform 0.3s ease;
}

/* Rotated state for the icon */
.chevron-icon.rotated {
    transform: rotate(-90deg);
}

/* Email transition styling */
.email {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.2;
    max-height: 0;
    /* Initially hidden */
    overflow: hidden;
    transition: max-height 0.3s ease;
    opacity: 0;
}

/* Show email on row expansion */
.email.show {
    max-height: 100px;
    /* Adjust this based on content height */
    opacity: 1;
}